// #region import
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
  Grid,
  Typography,
  IconButton,
  TextField
} from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';

// #endregion

// #region style

const useStyles = makeStyles(() => ({
  button: {
    width: '48px',
    height: '48px',
    marginTop: '30px',
    color: '#228822',
    '&:hover': {
      backgroundColor: '#ddd'
    }
  },
  fullHeight: {
    height: '100%'
  },
  marginBottom: {
    marginBottom: '40px'
  }
}));

// #endregion

const apiUrl = window.apiUrl

function Form(props) {
  const classes = useStyles()

  const [user, setUser] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  
  function connect() {
    setPassword("")
    setLoading(true)
    axios.get(apiUrl + "Connect", { headers: props.application == null ? { username: user, password: password } : { username: user, password: password, application: props.application } })
      .then(response => {
        if (response.data.valid) {
          props.connectAction(response.data)
        } else {
          props.updateStatus("warning", response.data.message)
          setLoading(false)
        }
      })
      .catch(response => {
        props.updateStatus("error", response.toString())
        setLoading(false)
      })
  }

  return (
    <Grid container direction="column" alignContent="center" justifyContent="center" className={classes.fullHeight}>
      <Typography variant='h6'>
        User
      </Typography>
      <TextField
        variant="outlined"
        className={classes.marginBottom}
        value={user} onChange={(event) => { setUser(event.target.value) }}
        disabled={loading} />
      <Typography variant='h6'>
        Password
      </Typography>
      <TextField
        variant="outlined"
        type="password"
        value={password}
        onChange={(event) => { setPassword(event.target.value) }}
        onKeyDown={(e) => { if (e.key === 'Enter') connect() }}
        disabled={loading} />
      <IconButton className={classes.button} onClick={connect} disabled={!user || !password || props.loading}>
        <DoneAllIcon fontSize="large" />
      </IconButton>
    </Grid>
  )
}

export default Form;