// #region import
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import {
  Grid,
  CircularProgress,
  Button,
  Typography,
  TextField,
} from '@material-ui/core';
import {
  useParams,
  useHistory
} from "react-router-dom";

// #endregion

// #region style

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    [theme.breakpoints.down('sm')]: {
      top: "0px",
    },
    [theme.breakpoints.up('sm')]: {
      bottom: "0px",
    },
    position: "absolute",
    width: "100%",
    color: "red",
    fontWeight: "bold"
  },
  fullHeight: {
    height: '100%'
  }
}));

// #endregion

const apiUrl = window.apiUrl

function Validation({ updateStatus }) {
  const classes = useStyles()
  var { validationToken } = useParams()

  const [loading, setLoading] = useState(false)

  const [password, setPassword] = useState("")
  const [passwordRepeat, setPasswordRepeat] = useState("")

  const [error, setError] = useState("")

  const history = useHistory()

  useEffect(() => {
    let value = ""
    if (password !== passwordRepeat) {
      value = "Passwords don't match"
    }
    if (password.length < 6) {
      value = "Password is too short"
    }
    setError(value)
  }, [password, passwordRepeat])

  function sendPassword() {
    setLoading(true)
    axios.post(apiUrl + "user/validate", {validationToken, password})
        .then(response => {
          if (response.data.valid) {
            updateStatus("success", response.data.message)
            history.push("/")
          } else {
            updateStatus("warning", response.data.message)
          }
          setPassword("")
          setPasswordRepeat("")
          setLoading(false)
        })
        .catch(response => {
          updateStatus("error", response.toString())
          setLoading(false)
        })
  }

  return (<>
    {loading ?
      <Grid container alignContent="center" justifyContent="center" className={classes.fullHeight}>
        <CircularProgress />
      </Grid>
      :
      <Grid container direction="column" alignContent="center" justifyContent="space-around" className={classes.fullHeight} style={{ position: "relative" }}>
        <Typography variant='h5' align="center">
          Enter a password
        </Typography>
        <Typography variant='subtitle1' align="center">
          Password must be longer than 6 characters
        </Typography>
        <div>
          <Typography variant='h6'>
            Password
          </Typography>
          <TextField
            variant="outlined"
            type="password"
            fullWidth
            value={password}
            onChange={(event) => { setPassword(event.target.value) }}
            disabled={loading} />
        </div>
        <div>
          <Typography variant='h6'>
            Confirm Password
          </Typography>
          <TextField
            variant="outlined"
            type="password"
            fullWidth
            value={passwordRepeat}
            onChange={(event) => { setPasswordRepeat(event.target.value) }}
            disabled={loading} />
        </div>
        <Button variant="contained" color="primary" style={{ marginBottom: "15px" }} disabled={error !== "" || loading} onClick={sendPassword}>
          Validate
        </Button>
        {
          error !== "" ?
            <Typography variant='subtitle2' align="center" className={classes.errorMessage}>
              {error}
            </Typography>
            : <></>
        }
      </Grid>
    }
  </>)
}

export default Validation;