// #region import
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {
  Grid,
  CircularProgress,
  Button
} from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import moment from 'moment';
import Form from '../components/Form';
import {
  useParams,
  useHistory
} from "react-router-dom";

// #endregion

// #region style

const useStyles = makeStyles((theme) => ({
  button: {
    width: '48px',
    height: '48px',
    marginTop: '30px',
    color: '#228822',
    '&:hover': {
      backgroundColor: '#ddd'
    }
  },
  fullHeight: {
    height: '100%'
  },
  continueButton: {
    color: '#4d7ac4',
    textTransform: 'none',
    marginBottom: '30px',
    padding: "20px",
    border: "1px solid #4d7ac4",
    '&:hover': {
      backgroundColor: '#eef'
    }
  },
  disconnect: {
    color: 'red',
    border: '1px solid red',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#eef'
    }
  },
  accountIcon: {
    fontSize: '5rem',
    width: '100%',
    color: '#4d7ac4',
    marginBottom: '25px'
  }
}));

// #endregion

const apiUrl = window.apiUrl

function Page({ updateStatus, setApp }) {
  const classes = useStyles()
  var { applicationName } = useParams()

  const [token, setToken, deleteToken] = useCookies(['token'])
  const [doOnce, setDoOnce] = useState(true)
  const [loading, setLoading] = useState(false)
  const [ssoUser, setssoUser] = useState(null)
  const [application, setApplication] = useState(null)

  const history = useHistory()

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      setLoading(true)
      axios.get(apiUrl + "application/get")
        .then(response => {
          var app = response.data.find(a => a.applicationName === applicationName)
          if (app != null) {
            setApp(applicationName)
            setApplication(app)
            if (token['token']) {
              axios.get(apiUrl + "Authenticate?token=" + token['token'] + '&application=' + applicationName)
                .then(response => {
                  if (response.data.valid) {
                    updateToken(token['token'])
                    setssoUser(response.data.login)
                  } else if (response.data.invalidReason === "403") {
                    updateStatus("warning", "Your are not allowed to use this app")
                    setApp(null)
                    history.push("/")
                  } else {
                    updateStatus("warning", "token not valid anymore")
                    deleteToken("token", { path: '/' })
                  }
                })
                .catch(response => {
                  updateStatus("error", response)
                  deleteToken("token", { path: '/' })
                  setLoading(false)
                })
              setLoading(false)
            } else{
              setLoading(false) 
            }
          } else {
            updateStatus("warning", "Application doesn't exists (" + applicationName + ")")
            setApp(null)
            history.push("/")
          }
        })
    }
  }, [doOnce])

  function disconnect() {
    deleteToken("token", { path: '/' })
    updateStatus("info", "Disconnected")
  }

  function updateToken(value) {
    setToken('token', value, { path: '/', expires: moment(new Date()).add(15, 'days').toDate() });
  }

  function connectAction(data) {
    updateStatus("success", "Hello " + data.displayName)
    updateToken(data.token)
    advance(data.token)
    setLoading(true)
  }

  function advance(token) {
    window.location.href = application.url + "Token/" + token
  }

  return (<>
    {loading ?
      <Grid container alignContent="center" justifyContent="center" className={classes.fullHeight}>
        <CircularProgress />
      </Grid>
      :
      <>
        {
          token['token'] ?
            <Grid container direction="column" alignContent="center" justifyContent="center" className={classes.fullHeight}>
              <AccountBoxIcon className={classes.accountIcon} />
              <Button endIcon={<DoneAllIcon />} className={classes.continueButton} onClick={() => advance(token['token'])}>
                Continue As {ssoUser}
              </Button>
              <Button endIcon={<ExitToAppIcon />} className={classes.disconnect} onClick={disconnect}>
                Disconnect
              </Button>
            </Grid>
            :
            <Form connectAction={(data) => connectAction(data)} updateStatus={updateStatus} application={applicationName} />
        }
      </>
    }
  </>)
}

export default Page;