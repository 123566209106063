// #region import
import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  Snackbar,
  Typography
} from '@material-ui/core';

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Alert from '@material-ui/lab/Alert';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Page from './pages/Page'
import Validation from './pages/Validation'
import DefaultPage from './pages/DefaultPage'

// #endregion

// #region style

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "5px",
    backgroundColor: '#eee',
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      height: "100%",
      borderRadius: 0
    },
    [theme.breakpoints.up('sm')]: {
      width: "400px",
      height: "400px",
      borderRadius: 5
    },
  },
  appBar: {
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: '#4d7ac4'
  },
  icon: {
    [theme.breakpoints.up('md')]: {
      width: '35px',
      height: '35px',
      margin: '0px 20px'
    },
    width: '25px',
    height: '25px',
    margin: '0px 10px',
    color: 'white'
  },
  title: {
    color: 'white'
  }
}));

// #endregion

function App() {

  const classes = useStyles()
  const [status, setStatus] = useState({})
  const [snackbar, setSnackbar] = useState(false)
  const [user, setUser] = useState(null)
  const [app, setApp] = useState(null)

  function updateStatus(severity, message) {
    setStatus({ severity, message })
    setSnackbar(true)
  }

  return (
    <>
      <div className={classes.appBar}>
        <AccountTreeIcon className={classes.icon} />
        {user ?
          <Typography className={classes.title}>
            {user}
          </Typography>
          : <></>
        }
        {app ?
          <Typography className={classes.title}>
            {app}
          </Typography>
          : <></>
        }
      </div>
      <Grid container spacing={0} justifyContent="center" alignContent="center" style={{ height: "calc(100vh - 50px)" }}>
        <Card className={classes.card}>
          <Router>
            <Switch>
            <Route path="/Validate/:validationToken">
                <Validation updateStatus={updateStatus} />
              </Route>
              <Route path="/:applicationName">
                <Page updateStatus={updateStatus} setApp={setApp}/>
              </Route>
              <Route path="/">
                <DefaultPage updateStatus={updateStatus} setUser={setUser} />
              </Route>
            </Switch>
          </Router>
        </Card>
      </Grid>
      <Snackbar
        open={snackbar}
        autoHideDuration={5000}
        onClose={() => { setSnackbar(false) }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity={status.severity ? status.severity : "error"} variant="filled" style={{ margin: "40px" }}>
          {status.message ? status.message : "error, no data transmited to this box"}
        </Alert>
      </Snackbar>
    </>
  )
}

export default App;
