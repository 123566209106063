// #region import
import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {
  Grid,
  Button,
  CircularProgress
} from '@material-ui/core';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Form from '../components/Form'
import moment from 'moment'

// #endregion

// #region style

const useStyles = makeStyles(() => ({
  fullHeight: {
    height: '100%'
  },
  fullWidth:{
    width: '100%',
    overflowY: 'auto',
    maxHeight: 'calc(100% - 40px)'
  },
  appButton: {
    color: '#4d7ac4',
    textTransform: 'none',
    padding: "20px",
    width: '100%',
    justifyContent: 'space-between',
    fontSize: 'x-large',
    '&:hover': {
      backgroundColor: '#ddd'
    }
  },
  disconnect: {
    color: 'red',
    border: '1px solid red',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'lightred'
    }
  },
}));

// #endregion

const apiUrl = window.apiUrl

function DefaultPage({ updateStatus, setUser }) {

  const classes = useStyles()
  const [token, setToken, deleteToken] = useCookies(['token'])
  const [applications, setApplications] = useState(null)
  const [loading, setLoading] = useState(false)
  const [doOnce, setDoOnce] = useState(true)

  useEffect(() => {
    if (doOnce) {
      setDoOnce(false)
      if (token['token']) {
        setLoading(true)
        axios.get(apiUrl + "Authenticate?token=" + token['token'])
          .then(response => {
            if (response.data.valid) {
              updateToken(token['token'])
              setUser(response.data.displayName)
              axios.get(apiUrl + "application/getlist", { headers: { token: token['token'] } })
                .then(input => {
                  setApplications(input.data)
                  setLoading(false)
                })
                .catch(response => {
                  updateStatus("error", response.toString())
                  deleteToken("token", { path: '/' })
                  setLoading(false)
                })
            } else {
              updateStatus("warning", "token not valid anymore")
              deleteToken("token", { path: '/' })
            }
          })
          .catch(response => {
            updateStatus("error", response)
            deleteToken("token", { path: '/' })
            setLoading(false)
          })
        setLoading(false)
      }
    }
  }, [doOnce])

  function connectAction(data) {
    updateStatus("success", "Hello " + data.displayName)
    setApplications(JSON.parse(data.message))
    updateToken(data.token)
  }

  function disconnect() {
    deleteToken("token", { path: '/' })
    updateStatus("info", "Disconnected")
  }

  function updateToken(value) {
    setToken('token', value, { path: '/', expires: moment(new Date()).add(15, 'days').toDate() });
  }

  return (<>
    {loading ?
      <Grid container alignContent="center" justifyContent="center" className={classes.fullHeight}>
        <CircularProgress />
      </Grid>
      :
      <>
        {token['token'] ?
          <Grid container direction="column" alignContent="center" justifyContent="space-between" className={classes.fullHeight}>
            <div className={classes.fullWidth}>
              {
                applications?.map(app => (
                  <Button
                    key={app.applicationName}
                    endIcon={<ArrowForwardIosIcon />}
                    className={classes.appButton}
                    onClick={() => { window.location.href = app.url + "Token/" + token['token'] }}>
                    {app.applicationName}
                  </Button>
                ))
              }
            </div>
            <Button endIcon={<ExitToAppIcon />} className={classes.disconnect} onClick={disconnect}>
              Disconnect
            </Button>
          </Grid>
          :
          <Form connectAction={(data) => connectAction(data)} updateStatus={updateStatus} />
        }
      </>
    }
  </>
  )
}

export default DefaultPage;